import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import FormWithBulletpoints from "components/ui/base/FormWithBulletpoints";

interface IndexPageProps {
  location: Location;
}

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        withCta: false,
        mobileCta: false,
      }}
    >
      <SEO
        title={"Customerly Success Case Study"}
        description={
          "Learn how Customerly helped a SaaS company to automate 60% of their tickets, increase CSAT by 20% and reduce response times to 12s."
        }
        withoutTitleSuffix={true}
        image={{
          relativePath: "meta/TeamSystem-fattureincloud.jpg",
          alt: "Customer Operating System for SaaS",
        }}
      />

      <PlainHeaderWithVideo
        preTitle={"Learn from Success Case Study"}
        title={"60% Ticket Automation, 20% CSAT Increase, 12s Response Times"}
        ctaButton={{
          show: true,
          text: "Get a Demo",
          icon: "arrow-right",
          path: "#demo-form",
        }}
        wistia={{
          id: "0cquc6xb76",
        }}
      />

      <FormWithBulletpoints
        elementId={"demo-form"}
        title={"In This Free Demo, We’ll Show You:"}
        bulletpoints={[
          "All the ways you can use automation without sacrificing support quality.",
          "Answer any questions, feel free to invite your support team!",
          "How to do all of this <u>without having to redo your knowledge base</u>.",
        ]}
      />
    </GlobalWrapper>
  );
};
